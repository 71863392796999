import React, { useEffect } from 'react';
import WebinarPage from '../components/webinar-page';
import '../styles/webinar-page.css';
///////////////////////////////////////////////////////////////////////////////////////////////////////////
const WebinarTrademarkInfringement = () => {
  //
  const webinarUrl = "https://webinarkit.com/webinar/registration/oneclick/67bc38a8e2aace524737b03f?date=jit_15";
  const title = "Put an Absolute Stop to Trademark Theft in 2025: Here's How!";
  const canonical = "/webinar-trademark-infringement-prevention-and-resolution";
  const introAuthor = "Bao Tran is an IP attorney with a focus on trademark protection"
  const lessons = [
    "How to identify and prevent trademark infringement.",
    "Legal remedies available for trademark disputes.",
    "Building a proactive trademark surveillance and monitoring plan.",
    "Case studies on successful infringement resolution."
  ]
  const btnTxt = "Watch Webinar Now";
  useEffect(() => {
    document?.body.classList.add('no-home');
    //
  }, []);

  return (
    <>
    <WebinarPage webinarUrl={webinarUrl} introAuthor={introAuthor} title={title} canonical={canonical} lessons={lessons} btnTxt={btnTxt}></WebinarPage>
    </>
  )
};

export default WebinarTrademarkInfringement;